<template>
    <div>
        <BookingTitle />
        <div class="booking-form">
            <BookingForm />
        </div>
    </div>
</template>

<!-- eslint-disable no-empty -->

<script>
import BookingForm from '../components/FacebookForm/BookingForm.vue'
import BookingTitle from '../components/FacebookForm/BookingTitle.vue'

// Components

export default {
    components: { BookingForm, BookingTitle },
    mounted() {
        const canonicalUrl = `https://buybusinessclass.com/book-now`

        const canonicalLink = document.createElement('link')
        canonicalLink.rel = 'canonical'
        canonicalLink.href = canonicalUrl
        document.head.appendChild(canonicalLink)
    },
}
</script>

<style lang="scss">
@import '@/styles/mixins.scss';
.booking-form {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
}
</style>
