export default {
    setDynamicPageMeta(state, value) {
        state.meta = value
    },
    setDynamicPageMainTitle(state, value) {
        state.mainTitle = value
    },
    setDynamicPageAbout(state, value) {
        state.about = value
    },
}
