// Router
import { createRouter, createWebHistory } from 'vue-router'

// Layouts
import MarginLayout from '@/layouts/MarginLayout.vue'
import HeaderLayout from '@/layouts/HeaderLayout.vue'

// Components
import MainPage from './pages/MainPage.vue'
import BlackFridayPage from './pages/BlackFridayPage.vue'
import FbBookingForm from './pages/FbBookingForm.vue'
import XmasPage from './pages/XmasPage.vue'
const BlogPage = () => import('@/pages/BlogPage.vue')
const AirlinesPage = () => import('@/pages/AirlinesPage.vue')
const AirlineDetail = () => import('@/pages/AirlineDetail.vue')
const FlightPage = () => import('@/pages/FlightPage.vue')
const BestDeals = () => import('@/pages/BestDeals.vue')
const ReviewsVue = () => import('@/pages/ReviewsVue.vue')
const DynamicPage = () => import('@/pages/DynamicPage.vue')
const InnerBlogPage = () => import('@/pages/InnerBlogPage.vue')
const FlightQuotePage = () => import('@/pages/FlightQuotePage.vue')
const AllDeals = () => import('@/pages/AllDeals.vue')
const TermsPrivacyPage = () => import('@/pages/TermsPrivacyPage.vue')
const AboutUsPage = () => import('@/pages/AboutUs.vue')
const BusinessFlights = () => import('@/pages/BusinessFlights.vue')
const Error404 = () => import('@/pages/404Error.vue')

const routes = [
    {
        path: '/',
        component: MarginLayout,
        children: [
            {
                path: '/',
                name: 'index',
                component: MainPage,
                meta: {
                    description:
                        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!',
                },
            },
            {
                path: '/airlines',
                component: AirlinesPage,
                meta: {
                    canonical: true,
                    description:
                        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!',
                },
            },
            {
                path: '/about-us',
                component: AboutUsPage,
                meta: {
                    canonical: true,
                    description:
                        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!',
                },
            },
            {
                path: '/airlines/:slug',
                name: 'AirlineDetail',
                component: AirlineDetail,
                props: true,
                meta: {
                    canonical: true,
                },
            },
            {
                path: '/business-class-flights',
                name: 'BusinessFlights',
                component: BusinessFlights,
                meta: {
                    canonical: true,
                },
            },
            {
                path: '/blog',
                component: BlogPage,
                meta: {
                    description:
                        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!',
                },
            },
            {
                name: 'inner-blog',
                path: '/blog/:id',
                component: InnerBlogPage,
                meta: {
                    description:
                        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!',
                },
            },
            {
                path: '/flight/region/:region/:id',
                name: 'flight-region',
                component: FlightPage,
                meta: {
                    canonical: true,
                },
            },
            {
                path: '/flight/country/:country/:id',
                name: 'flight-country',
                component: FlightPage,
                meta: {
                    canonical: true,
                },
            },
            {
                path: '/flight/city/:city/:id',
                name: 'flight-city',
                component: FlightPage,
                meta: {
                    canonical: true,
                },
            },
            {
                path: '/best-deals/:region/:id',
                name: 'best-deals-region',
                component: BestDeals,
                meta: {
                    canonical: true,
                    description:
                        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!',
                },
            },
            {
                path: '/reviews',
                component: ReviewsVue,
                meta: {
                    description:
                        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!',
                },
            },
            {
                path: '/black-friday-busines-class-seat-sale',
                component: BlackFridayPage,
                meta: {
                    description:
                        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!',
                },
            },
            {
                path: '/xmas-new-year-flight-sale',
                component: XmasPage,
                meta: {
                    description:
                        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!',
                },
            },
            {
                path: '/book-now',
                component: FbBookingForm,
                meta: {
                    description:
                        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!',
                },
            },
            {
                path: '/flight',
                name: 'search-results',
                component: FlightQuotePage,
            },
            {
                path: '/business-class-:suffix',
                name: 'dynamic-page',
                component: DynamicPage,
                meta: {
                    canonical: true,
                },
            },
        ],
    },
    {
        path: '/',
        component: HeaderLayout,
        children: [
            {
                path: '/all-deals',
                component: AllDeals,
                meta: {
                    canonical: true,
                    description:
                        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!',
                },
            },
            {
                path: '/terms',
                alias: '/terms-and-conditions',
                component: TermsPrivacyPage,
                meta: {
                    description:
                        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!',
                },
            },
            {
                path: '/privacy',
                alias: '/privacy-policy',
                component: TermsPrivacyPage,
                meta: {
                    description:
                        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!',
                },
            },
            {
                path: '/:pathMatch(.*)',
                name: 'error-404',
                component: Error404,
            },
        ],
    },
]

// Add here in production history: createWebHistory(), ?? createWebHashHistory()

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior() {
        return { top: 0 }
    },
    routes,
})

router.beforeEach((to, from, next) => {
    const defaultDescription =
        'Discover luxury business class travel at BuyBusinessClass. Personalized service, spacious seating, and unbeatable fares to all destinations worldwide. Book now!'
    const description = to.meta.description || defaultDescription

    document.querySelector('meta[name="description"]').setAttribute('content', description)
    next()
})

export default router
